import * as React from "react";
import { graphql } from "gatsby";
import Styled from "styled-components/macro";
import Meta from "../../../components/meta";
import Layout from "../../../components/layout/Layout";
import PartnerHeaderBanner from "../../../components/pages/partnerships/PartnerHeaderBanner";
import SectionWithRightImage from "../../../components/pages/solutions/SectionWithRightImage";
import SectionWithLeftImage from "../../../components/pages/solutions/SectionWithLeftImage";
import SectionWithVerticalImage from "../../../components/pages/solutions/SectionWithVerticalImage";
import HomeAutodesignerLeft from "../../../components/pages/home/HomeAutodesignerLeft";
import HomeAutodesigner from "../../../components/pages/home/HomeAutodesigner";
import HomeFeatured from "../../../components/pages/home/HomeFeatured";
import Shoutout from "../../../components/pages/signup/Shoutout";
import Content from "../../../components/layout/Content/Content";
import Description from "../../../components/texts/Description";
import Media from "@uizard-io/styled-media-query";

const ContentExtended = Styled(Content)`
  margin-top: 9rem;
`;

const Disclosure = Styled.div`
  border: 1px solid ${(props) => props.theme.colors.gray.light};
  background: ${(props) => props.theme.colors.gray.lighter};
  padding: 1rem 4rem;
  border-radius: 2rem;
  margin-top: 4rem;

  ${Media.greaterThan("medium")`
    padding: 3rem 8rem;
  `}
`;

const DisclosureDescription = Styled(Description)`
  font-size: 1.9rem;
  margin: 2rem auto 3rem auto;
  text-align: left;
`;

const NavButtonLink = Styled.a`
  margin-top: 2rem;
  font-size: 1.6rem;
`;

const Home = ({ data }) => (
  <Layout>
    <Meta
      title="Uizard & Microsoft for Startups | The partnership made in heaven to help startup founders bring their ideas to life"
      description="Design mobile apps, web apps, websites, and desktop software in minutes. If you can use Google Slides, Keynote, or PowerPoint, you can use Uizard!"
      url="/lp/microsoft-for-startups/"
      noindex={true}
    />

    <PartnerHeaderBanner
      tagline="Bring your MVP to life with the power of AI!"
      headline1="Microsoft for Startups Founders Hub members now have access to exclusive Uizard offers."
      uizardLogo={data.uizardLogo.childImageSharp}
      partnerLogo={data.microsoftForStartupsLogo.childImageSharp}
      partnerLogoAlt="logo Microsoft for Startups Founders Hub"
    />

    <SectionWithRightImage
      headline="For startup founders and entrepreneurs"
      description="Design at speed with wireframes and interactive prototypes. Create your mobile app, SaaS web app, desktop app, or website design in minutes — no tutorial or design pros needed."
      imageA={data.foundersCoverBig.childImageSharp}
      imageB={data.foundersCoverSmall.childImageSharp}
      imageAlt="A mobile app and a website designed in Uizard"
    />

    <HomeAutodesignerLeft
        headline="Text to UI design in seconds with Autodesigner"
        description="Text to design is here! <a:https://uizard.io/autodesigner/>Generate</a> editable, multi- screen designs from simple text prompts with Uizard Autodesigner."
        imageA={data.autodesignerImageA.childImageSharp}
        imageB={data.autodesignerImageB.childImageSharp}
        imageAlt="turn text prompts into UI designs with AI"
        videoName="uizard-autodesigner-light"
    />

    <HomeAutodesigner
        headline="Turn screenshots into editable designs in seconds"
        description="Upload screenshots of inspiration apps and websites and transform them into fully customizable design mockups."
        imageA={data.hificonversionImageA.childImageSharp}
        imageB={data.hificonversionImageB.childImageSharp}
        imageAlt="convert screenshots into editable UI mockups"
        videoName="uizard-scan-screenshot"
        videoH={460}
    />

    <SectionWithVerticalImage
        headline="Visualize your ideas at lightning speed"
        description="Turn your ideas into testable <a:https://uizard.io/mockups/>UI mockups</a> in minutes, not months. Get started in seconds with Uizard's templates, no tutorial needed!"
        imageA={data.foundersRapidlyVisualizeBig.childImageSharp}
        imageB={data.foundersRapidlyVisualizeSmall.childImageSharp}
        imageAlt="Multiple steps of a screen design in Uizard"
    />

    <SectionWithRightImage
        headline="Get feedback instantly"
        description="Simply share a link to your project to collaborate with your team or pitch to investors — all from your web browser!"
        imageA={data.foundersRealTimeCollaborationBig.childImageSharp}
        imageB={data.foundersRealTimeCollaborationSmall.childImageSharp}
        imageAlt="Visual showing an overview of Uizard's collaborative features"
        marginBottom="5rem"
    />

    <SectionWithLeftImage
        headline="Drive toward success"
        description="Create your wireframe, mockup, or interactive prototype in minutes. Rapidly improve and adapt your designs based on stakeholder feedback. Ideate and design alone, or as part of a team."
        imageA={data.foundersDriveTowardSuccessBig.childImageSharp}
        imageB={data.foundersDriveTowardSuccessSmall.childImageSharp}
        imageAlt="A visual showcasing multiple users designing an interactive prototype in Uizard."
    />

    <HomeFeatured />

    <ContentExtended>
      <Shoutout
        headline="Design your startup MVP in no time"
        description="Bring your vision to life in minutes"
        cta="Sign up for free"
        imageA={data.shoutoutImageA.childImageSharp}
        link={`https://uizard.typeform.com/to/m3csetcP`}
      />
      <Disclosure>
        <DisclosureDescription>
          This site is jointly operated by Microsoft and Uizard, and both companies are committed to protecting your privacy. Any personal data we collect from you on this site may be shared between Microsoft and Uizard. For complete information on the data collection and use practices of each company, please read the full privacy statements by clicking on the links below.
        </DisclosureDescription>

        <DisclosureDescription>
          <NavButtonLink href="https://privacy.microsoft.com/en-us/privacystatement" target="_blank">Microsoft Privacy Statement</NavButtonLink>
          <br/>
          <NavButtonLink href="/privacy/" target="_blank">Uizard Privacy Policy</NavButtonLink>
        </DisclosureDescription>
      </Disclosure>
    </ContentExtended>
  </Layout>
);

export default Home;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    microsoftForStartupsLogo: file(
      relativePath: { eq: "partnerships/MS_startups_logo.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    uizardLogo: file(
      relativePath: { eq: "partnerships/uizard_logo_yellow.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1498, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    foundersCoverBig: file(
      relativePath: { eq: "solutions/UizardFor_Founders2x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1350, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    foundersCoverSmall: file(
      relativePath: { eq: "solutions/UizardFor_Founders1x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    autodesignerImageA: file(
      relativePath: { eq: "ai/uizard-autodesigner-light-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    autodesignerImageB: file(
      relativePath: { eq: "ai/uizard-autodesigner-light-b.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    foundersRapidlyVisualizeBig: file(
      relativePath: { eq: "founders/Founders_RapidlyVisualize_2x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2140, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    foundersRapidlyVisualizeSmall: file(
      relativePath: { eq: "founders/Founders_RapidlyVisualize_1x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1070, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    hificonversionImageA: file(
      relativePath: { eq: "ai/HifiConversion-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    hificonversionImageB: file(
      relativePath: { eq: "ai/HifiConversion-b.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 501, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    foundersRealTimeCollaborationBig: file(
      relativePath: { eq: "prototyping/Proto_Collaborate2x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1152, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    foundersRealTimeCollaborationSmall: file(
      relativePath: { eq: "prototyping/Proto_Collaborate1x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 576, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    foundersDriveTowardSuccessBig: file(
      relativePath: { eq: "founders/Founders_DriveTowardSuccess_2x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1116, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    foundersDriveTowardSuccessSmall: file(
      relativePath: { eq: "founders/Founders_DriveTowardSuccess_1x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 559, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    shoutoutImageA: file(
      relativePath: { eq: "shoutout/shoutout-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1232, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
