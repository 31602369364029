import * as React from 'react';

import Media from '@uizard-io/styled-media-query';
import Image, { GatsbyImageProps } from 'gatsby-image';
import Styled from 'styled-components/macro';

import Button from 'components/buttons/Button';
import Content from 'components/layout/Content/Content';
import Description from 'components/texts/Description';
import Headline from 'components/texts/Headline';

const Container = Styled.div`
  margin: 10rem 0 10rem 0;
  overflow: hidden; // fix for columns overflow

  ${Media.greaterThan('medium')`
    margin: 20rem 0 10rem 0;
  `}
`;

const Header = Styled.div`
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

const Columns = Styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;

  ${Media.greaterThan('medium')`
    flex-direction: row;
    padding: 0 9.5rem;
  `}
`;

const CardOffer = Styled.div`
  margin: 6rem 0 0 0;
  width: 100%;
  padding-right: 0;
  text-align: center;
  padding: 4rem;
  box-shadow: rgba(3, 3, 3, 0.1) 0px 2px 20px;
  border-radius: 2rem;
  
  ${Media.greaterThan('large')`
    margin: 12rem 0 2rem 0;
    width: 49rem;
  `}
`;

const HeadlineWrapper = Styled.div`
  max-width: 100%;
  
  ${Media.greaterThan('medium')`
    max-width: 65rem;
  `}
`;

const DescriptionExtended = Styled(Description)`
  width: 28.5rem;
  margin: 2rem auto 3rem auto;

  ${Media.greaterThan('medium')`
    width: auto;
    margin-bottom: 3rem;
    max-width: 72rem;
  `}
`;

const UpSell = Styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;

  ${Media.greaterThan('medium')`
    margin-top: 10rem;
  `}
`;

const OfferTagLine = Styled.span`
  font-size: 1.8rem;
  line-height: 1.25;
  font-weight: 400;
  letter-spacing: -0.2px;
  font-weight: 900;

  ${Media.greaterThan('large')`
    font-size: 2.4rem;
  `}
`;

const OfferDescription = Styled(Description)`
  margin: 2rem auto 3rem auto;
  text-align: left;
`;

const ExtendedHeadline = Styled(Headline)`
  text-align: center;
  font-size: 3.6rem;
  line-height: 3.8rem;
  max-width: 100%;
  margin: 0 0 2rem 0;
  
  ${Media.greaterThan('medium')`
    width: auto;
    margin: 0 0 3rem 0;
  `}

  ${Media.greaterThan('large')`
    font-size: 5.6rem;
    line-height: 6.5rem;
  `}
`;

const ImageExtended = Styled(Image)`
  width: 120px;
  margin: 0 auto;

  ${Media.greaterThan('medium')`
    width: 190px;
  `}
`;

const Logos = Styled(Columns)`
  justify-content: center;
  gap: 10px;
  align-items: center;
  flex-direction: row;

  ${Media.greaterThan('medium')`
    gap: 60px;
  `}
`;

const NavButtonLink = Styled.a`
  
`;

const ContentLink = Styled.a`
  font-weight: bold;
`;

type BannerProps = {
  tagline: string;
  headline1: string;
  uizardLogo: GatsbyImageProps;
  partnerLogo: GatsbyImageProps;
  partnerLogoAlt: string;
};

const PartnerHeaderBanner = ({
  tagline,
  headline1,
  uizardLogo,
  partnerLogo,
  partnerLogoAlt,
}: BannerProps) => (
  <Container>
    <Content>
      <Header>
        <HeadlineWrapper>
          <ExtendedHeadline as="h1">
            {tagline}
          </ExtendedHeadline>
        </HeadlineWrapper>

        <DescriptionExtended>
          {headline1}
        </DescriptionExtended>

        <Logos>
          <ImageExtended
            fluid={'fluid' in uizardLogo ? uizardLogo.fluid : null}
            alt="Uizard light logo"
          />

          <NavButtonLink
            href="https://foundershub.startups.microsoft.com/signup"
          >
            <ImageExtended
              fluid={'fluid' in partnerLogo ? partnerLogo.fluid : null}
              alt={partnerLogoAlt}
            />
          </NavButtonLink>

        </Logos>
      </Header>
      <Columns>
        <CardOffer>
          <Headline>Free plan</Headline>

          <OfferTagLine>
            Try out Uizard's free plan.
          </OfferTagLine>

          <OfferDescription>
            Build your first prototype in seconds, create interactions across buttons & screens, turn sketches & screenshots into editable UI designs, and more. Get started with up to 2 projects, 5 screens per projects, and 10 pre-made templates.
          </OfferDescription>

          <NavButtonLink
            href="https://uizard.typeform.com/to/m3csetcP"
          >
            <Button solid variant="black">Sign up free now</Button>
          </NavButtonLink>
        </CardOffer>

        <CardOffer>
          <Headline noRightPadding>Monthly/Yearly offer</Headline>

          <OfferTagLine>
            Get the Uizard Pro plan at 50% off
          </OfferTagLine>

          <OfferDescription>
            Bring your digital product ideas to life and innovate faster than ever before with full access to <ContentLink href="/autodesigner/">Autodesigner</ContentLink>, a huge template library, unlimited screens, and more! Get 50% off on the yearly plan or 50% off for 3 months on the monthly plan.
          </OfferDescription>

          <NavButtonLink
            href="https://uizard.typeform.com/to/IxIjEOoy"
          >
            <Button solid>Claim 50% off now</Button>
          </NavButtonLink>
        </CardOffer>
      </Columns>

      <UpSell>
        <DescriptionExtended>
          Ready to bring along the whole team? <ContentLink href="/enterprise/?req=header#contact_sales">Contact sales</ContentLink> to get more info on our business and enterprise plans, and receive an exclusive, custom plan for your needs.
        </DescriptionExtended>
      </UpSell>

    </Content>
  </Container>
);

export default PartnerHeaderBanner;
